import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenEmpresa from '../components/imageComponents/imageEmpresa'

const EnEmpresaPage = () => (
  <EnLayout>
    <SEO title='OR-PRINTER' />
    <Header siteTitle='OR-PRINTER' />

<Container>
        <div className='row '>
        <div className='col-sm-12 col-md-5 '>
            <ImagenEmpresa />
          </div>
          <div className='col-sm-12 col-md-7 col-lg-7 conmargen '>
          <p ><b><span className="oro"> OR-PRINTER </span> </b> was founded in April 1985, with the aim of manufacturing a small manual thermal printing machine for the printing press.
           In 1990 the range of machine models was expanded to make more automated models in order to produce for more sectors and more production.
           With a clear spirit of innovation, it has not stopped evolving since then, currently producing 5 types of machines.
        </p>
        <p  >
        <b><span className="oro"> OR-PRINTER </span></b> has a large national presence and exports a large part of its production
         to the rest of Europe and America, with 90% of production currently destined for export.
        </p>
        <p  >
        <b><span className="oro"> OR-PRINTER </span></b> supports all types of printing jobs, supplying both the machines and all the necessary consumables.
         foils in the full range of colors and prints.
        </p>
        </div></div>

    </Container>
  </EnLayout>
)

export default EnEmpresaPage
